import { CASE_CHANGE_PAGE, CONTENT_GROUP_TYPE } from 'configs/AppConfig';
import HomeTypes from './HomeTypes';

const initialState = {
  contentGroups: [],
  contentGroupByContentId: [],
  showFavorite: false,
  showHomeSearch: false,
  searchRequest: [],
  fullTextSearchRequest: [],
  fulltextSearch: '',
  contentsByGroup: null,
  isClearData: false,
  searchResult: {
    contents: [],
    page: 0,
    totalPages: 0,
    isLoading: false,
    isReload: false
  },
  loadingByGroup: {},
  dataNextPage: {},
  dataPrevPage: {},
  sortOrder: {
    sort: undefined,
    sortField: undefined
  }
  // showTrending: false
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case HomeTypes.GET_PUBLIC_CONTENT_GROUP_SUCCESS:
      newState.contentGroups = payload;
      newState.showFavorite = !!payload.filter(item => item.default && item.type === CONTENT_GROUP_TYPE.FAVORITE)[0];
      // newState.showTrending = !!payload.filter(item => item.default && item.type === CONTENT_GROUP_TYPE.TRENDING)[0];
      // var newData = payload || [];
      // newState.showFavorite = !!newData.filter(item => item.default && item.type === CONTENT_GROUP_TYPE.FAVORITE)[0];
      return newState;
    case HomeTypes.GET_CONTENT_GROUP_BY_CONTENT_ID_SUCCESS:
      newState.contentGroupByContentId = payload ? payload.map(item => item.id) : [];
      return newState;
    case HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_SUCCESS:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item.contents = payload.content;
          item.page = payload.pageNumber + 1;
          item.totalPages = payload.totalPages;
          item.isLoading = false;
          item.isReload = false;
        }
        return item;
      });
      return newState;
    case HomeTypes.GET_PAGE_CONTENT_BY_CONTENT_GROUP_REQUEST:
      newState.loadingByGroup = {
        ...newState.loadingByGroup,
        [payload.contentGroupId]: true
      };
      return newState;
    case HomeTypes.UPDATE_SORT_ORDER:
      newState.sortOrder = { ...payload };
      return newState;
    case HomeTypes.UPDATE_DATA_NEXT_PAGE:
      newState.dataNextPage = { ...newState.dataNextPage, [payload.contentGroupId]: payload.data };
      return newState;
    case HomeTypes.UPDATE_DATA_PRE_PAGE:
      newState.dataPrevPage = { ...newState.dataPrevPage, [payload.contentGroupId]: payload.data };
      return newState;
    case HomeTypes.UPDATE_DATA_CURRENT_PAGE:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          item = payload.data;
        }
        return item;
      });
      return newState;
    case HomeTypes.GET_PAGE_CONTENT_BY_CONTENT_GROUP_SUCCESS:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.id === payload.contentGroupId) {
          switch (payload.caseChangePage) {
            case CASE_CHANGE_PAGE.pagination:
              if (payload.isIncremental) {
                if (payload.pageNumber + 1 > item.page) {
                  const contentGroupEdit = { ...item };
                  const newList = contentGroupEdit.contents && payload.content ? [...contentGroupEdit.contents, ...payload.content] : payload.content;
                  const setId = new Set(newList.map(item => item.id));
                  if (setId.size === newList.length) {
                    contentGroupEdit.contents = newList;
                    contentGroupEdit.page = payload.pageNumber + 1;
                  }
                  item = { ...contentGroupEdit };
                }
              } else {
                const contentGroupEdit = { ...item };
                contentGroupEdit.contents = payload.content;
                item = { ...contentGroupEdit };
                item.page = payload.pageNumber + 1;
              }
              break;
            case CASE_CHANGE_PAGE.scroll:
              if (payload.pageNumber + 1 > item.page) {
                const contentGroupEdit = { ...item };
                let newList = [];
                if (contentGroupEdit.contents && payload.content) {
                  newList = [...contentGroupEdit.contents, ...payload.content];
                } else if (payload.content) {
                  newList = [...payload.content];
                }
                const setId = new Set(newList.map(item => item.id));
                if (setId.size === newList.length) {
                  contentGroupEdit.contents = newList;
                  contentGroupEdit.page = payload.pageNumber + 1;
                }
                item = { ...contentGroupEdit };
              }
              break;
            default:
              if (payload.content && payload.pageNumber + 1 !== item.page) {
                const data = { ...item };
                data.contents = payload.content;
                data.page = payload.pageNumber + 1;
                data.totalPages = payload.totalPages;
                data.isLoading = false;
                data.isReload = false;
                data.isNext = payload.isNext;
                if (payload.isNext) {
                  newState.dataNextPage = { ...newState.dataNextPage, [payload.contentGroupId]: data };
                } else {
                  newState.dataPrevPage = { ...newState.dataPrevPage, [payload.contentGroupId]: data };
                }
              }
              break;
          }
          item.isLoading = false;
          item.isReload = false;
          item.totalPages = payload.totalPages;
        }
        return item;
      });

      // newState.contentsByGroup = { ...data };
      newState.loadingByGroup = {
        ...newState.loadingByGroup,
        [payload.contentGroupId]: false
      };
      return newState;
    case HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_SUCCESS:
    case HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_SUCCESS:
      if (payload.contentGroupId) {
        newState.contentGroups = newState.contentGroups.map(item => {
          if (item.id === payload.contentGroupId) {
            item.contents = payload.content;
            item.page = payload.pageNumber + 1;
            item.totalPages = payload.totalPages;
            item.isLoading = false;
            item.isReload = false;
          }
          return item;
        });
      } else {
        const newSearchResult = { ...newState.searchResult };
        newSearchResult.contents = payload.content;
        newSearchResult.page = payload.pageNumber + 1;
        newSearchResult.totalPages = payload.totalPages;
        newSearchResult.isLoading = false;
        newSearchResult.isReload = false;
        newState.searchResult = { ...newSearchResult };
      }
      return newState;
    case HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST:
      if (payload.pagination.contentGroupId) {
        newState.contentGroups = newState.contentGroups.map(item => {
          if (item.id === payload.pagination.contentGroupId) {
            item.isLoading = true;
            item.isReload = false;
          }
          return item;
        });
      } else {
        const newSearchResult = { ...newState.searchResult };
        newSearchResult.isLoading = false;
        newSearchResult.isReload = false;
        newState.searchResult = { ...newSearchResult };
      }
      return newState;
    case HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST:
    case HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST:
      if (payload.contentGroupId) {
        newState.contentGroups = newState.contentGroups.map(item => {
          if (item.id === payload.contentGroupId) {
            item.isLoading = true;
            item.isReload = false;
          }
          return item;
        });
      } else {
        const newSearchResult = { ...newState.searchResult };
        newSearchResult.isLoading = true;
        newSearchResult.isReload = true;
        newState.searchResult = { ...newSearchResult };
      }
      return newState;
    case HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_ERROR:
    case HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_ERROR:
    case HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_ERROR:
      if (payload.contentGroupId) {
        newState.contentGroups = newState.contentGroups.map(item => {
          if (item.id === payload.contentGroupId) {
            item.isLoading = false;
            item.isReload = false;
            item.contents = [];
          }
          return item;
        });
      } else {
        const newSearchResult = { ...newState.searchResult };
        newSearchResult.isLoading = false;
        newSearchResult.isReload = false;
        newSearchResult.contents = [];
        newState.searchResult = { ...newSearchResult };
      }
      return newState;
    case HomeTypes.CLEAR_CONTENTS:
      newState.contentGroups = newState.contentGroups.map(item => {
        item.contents = null;
        item.page = 1;
        item.isLoading = false;
        item.isReload = false;
        return item;
      });
      newState.isClearData = !newState.isClearData;
      return newState;
    case HomeTypes.SYNC_CONTENT_OF_CONTENT_GROUP:
      newState.contentGroups = newState.contentGroups.map(item => {
        if (item.contents && item.contents.length > 0) {
          if (item.id === payload.contentGroupId) {
            item.isReload = true;
            item.isLoading = false;
            return item;
          }
        }
        return item;
      });
      return newState;
    case HomeTypes.SET_SHOW_HOME_SEARCH:
      newState.showHomeSearch = payload;
      return newState;
    case HomeTypes.CACHE_SEARCH_REQUEST:
      newState.cacheSearchRequest = payload;
      return newState;
    case HomeTypes.SET_SEARCH_REQUEST:
      newState.searchRequest = payload;
      return newState;
    case HomeTypes.SET_SEARCH_REQUEST_FULL_TEXT_SEARCH:
      newState.fullTextSearchRequest = payload;
      return newState;
    case HomeTypes.SET_FULLTEXT_SEARCH:
      newState.fulltextSearch = payload;
      return newState;
    default:
      newState = state;
      break;
  }
  // return new state
  return newState;
}
