import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Drawer, Grid, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { Menu } from '@material-ui/icons';
import { ActionContext } from 'components/layout/MainContainer';
import { FastField, Form, Formik } from 'formik';
// import SwitchField from '../Input/SwitchField';
// import SearchBox from 'components/search/SearchBox';
// import SearchResult from 'components/search/SearchResult';
// import { useIntl } from 'react-intl';
import { getIdTabActive, getInitialValuesSearch, getIsOpenSearchFullScreen, getSearchQuery, getSearchSetting, getTabs } from 'state/search/searchSelector';
import { BUTTON_ACTION, CONTENT_GROUP_TYPE, PAGINATION, PAGING_TYPE, SEARCH_BOX_OPERATION, SEARCH_FIELD, TAB_TYPE } from 'configs/AppConfig';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import InputField from '../Input/InputField';
import TranslateMessage from '../TranslateMessage/TranslateMessage';
// import StorageHelper from 'utils/StorageHelper';
import Util from 'utils/Util';
import { getLayoutSetting, getPageMargin, getServicePath } from 'state/company/companySelector';
import { isAuthenticated } from 'state/session/sessionSelector';
import { useIntl } from 'react-intl';
import InputDateRange from '../DateRangePicker/DateRangePicker';
import { useMedia } from 'react-use';

// const margin = 100;
const IconMenuBarButton = (props) => {
  const {
    label,
    tabs,
    servicePath,
    isLoggedIn,
    layoutSetting,
    searchSetting,
    searchActions: {
      getData: getListContent,
      getPublicData: getListPublicContent,
      searchFullText,
      setInitialValuesSearch: setInitialValues,
      setIdTabSearch,
      setOpenSearchFullScreen,
      setIsFullTextSearch,
      setSpecialContentsGroupId,
      loadContentByContentGroupSpecial
    },
    pageMargin
  } = props;

  const {
    searchValueBorderColor,
    unselectSearchValueTextColor: searchValueTextColor,
    selectSearchValueTextColor: searchValueTextHighlight,
    unselectSearchValueBackground: searchValueBackground,
    selectSearchValueBackground: searchValueBackgroundHighlight
  } = layoutSetting;

  const { left: marginLeft, right: marginRight } = pageMargin;
  const below768 = useMedia(`(max-width: ${marginLeft + marginRight + 393}px)`);

  const { formatMessage: f } = useIntl();
  const { contentId } = useParams();
  const history = useHistory();
  // const pathname = window.location.pathname;
  const idTabActive = useSelector(getIdTabActive);
  const initialValues = useSelector(getInitialValuesSearch) || {};
  // const isFulltextSearch = useSelector((state) => state.search.isFulltextSearch);
  // const { formatMessage: f } = useIntl();
  const [activeTab, setActiveTab] = useState('1');

  /**
   * @Object tabSearchMap
   * {
   *    <Map>: {
   *      id: <Number>,
   *      title: <String>,
   *      searchBoxMap: {
   *        <Map>: {
   *          id: <Number>,
   *          isInputSearch: <bool>,
   *          isSingle: <bool>,
   *          label: <String>,
   *          operation: <Number>,
   *          ordinalNumber: <Number>,
   *          size: <Number>,
   *          tabId: <Number>,
   *          matchValue: [
   *            {
   *              id: <String>,
   *              checked: <bool>,
   *              value: <String>,
   *            }
   *          ]
   *        }
   *      },
   *    }
   * }
   */
  const [tabSearchMap, setTabSearchMap] = useState(new Map());
  const [initialValuesDefault, setInitialValuesDefault] = useState({});
  const tabSetting = useSelector((state) => state.search.tabSetting) || {};
  const IsOpenSearchFullScreen = useSelector(getIsOpenSearchFullScreen);
  const searchQuery = useSelector(getSearchQuery) || {};

  const styleButtonSearch = tabSetting.buttons && tabSetting.buttons.length > 0 ? tabSetting.buttons.filter(item => item.actionType === BUTTON_ACTION.SEARCH.id)[0] : {};

  const useStyles = makeStyles(theme => ({
    paperRoot: {
      marginTop: '3rem',
      minHeight: '100vh',
      paddingBottom: '100px',
      marginLeft: `${!below768 ? marginLeft : '15'}px`,
      marginRight: `${!below768 ? marginRight : '15'}px`
      // width: '100%',
      // paddingLeft: `${!below768 ? marginLeft : '10'}px`,
      // paddingRight: `${!below768 ? marginRight : '10'}px`
    },
    containerMenu: {
      display: 'flex',
      flexFlow: 'column nowrap',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      padding: '0 0.5rem',
      cursor: 'pointer'
    },
    containerPaper: {
      marginTop: '5%'
    },
    closeIcon: {
      zIndex: 5,
      position: 'absolute',
      top: 10,
      right: 20,
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '25px',
      '&:hover': {
        color: '#ABABAB'
      }
    },
    titleSearch: {
      margin: '25px 0'
    },
    chip: {
      '& .MuiChip-colorPrimary': {
        backgroundColor: searchValueBackgroundHighlight
      },
      '& .MuiChip-outlined': {
        color: searchValueTextColor,
        border: `1px solid ${searchValueBorderColor}`,
        backgroundColor: searchValueBackground
      },
      '& .MuiChip-clickable.MuiChip-outlined:focus': {
        backgroundColor: searchValueBackground
      },
      '& .MuiChip-clickable.MuiChip-outlined:hover': {
        backgroundColor: '#0000000a'
      },
      '& .MuiChip-label': {
        wordBreak: 'break-word',
        maxHeight: '75px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: below768 ? '90vw' : '',
        fontFamily: 'Noto Sans JP'
      }
    },
    chipActive: {
      backgroundColor: searchValueBackgroundHighlight,
      color: searchValueTextHighlight,
      '&:hover': {
        backgroundColor: '#cccccc'
      }
    },
    containerSearchKey: {
      marginTop: '15px'
    },
    titleSearchBox: {
      marginTop: '20px',
      wordBreak: 'break-all'
    },
    btnSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '50px',
      maxWidth: '100%',
      width: styleButtonSearch.width ? styleButtonSearch.width : '',
      border: '2px solid',
      fontWeight: 700,
      boxShadow: 'none!important',
      backgroundColor: styleButtonSearch.backgroundColor || '#333333',
      color: styleButtonSearch.color || '#fff',
      borderColor: styleButtonSearch.borderColor || 'transparent',
      height: styleButtonSearch.height || 'auto',
      paddingTop: styleButtonSearch.height ? 0 : 6,
      paddingBottom: styleButtonSearch.height ? 0 : 6,
      // marginRight: 15,
      '&:hover, &:focus, &:active': {
        backgroundColor: `${styleButtonSearch.hoverBackgroundColor || styleButtonSearch.backgroundColor || '#000000'}!important`,
        color: `${styleButtonSearch.hoverColor || styleButtonSearch.color || 'fff'}!important`,
        borderColor: `${styleButtonSearch.hoverBorderColor || styleButtonSearch.borderColor || 'transparent'}!important`
      }
    },
    boxSearchBottom: {
      flex: '1 0 0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.5rem 0'
    },
    iconButton: {
      marginRight: '0.5rem'
    },
    navItem: {
      // width: `${100 / tabs.length}%`,
      border: '1px solid #d0d0d0',
      flex: '1 0 0px',
      borderRadius: '5px 5px 0 0',
      marginLeft: 4,
      background: tabSetting.backgroundColorInactive || 'LightGray',
      borderColor: '#d0d0d0',
      fontWeight: 700,
      fontSize: tabSetting.fontSize || '1rem',
      textAlign: 'center',
      '&:first-child': {
        marginLeft: 0
      },
      '& .nav-link': {
        color: `${tabSetting.colorInactive || '#fff'}!important`
      }
    },
    activeNav: {
      border: '1px solid #d0d0d0',
      background: tabSetting.backgroundColor || '#3d993a',
      '& .nav-link': {
        color: `${tabSetting.color || '#fff'}!important`
      }
    },
    navlink: {
      border: 'none!important',
      borderRadius: '0!important',
      backgroundColor: 'unset!important',
      color: '#fff!important'
    }
  }));
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenSearchFullScreen(open);
  };

  const handleSearch = (formValue, tab) => {
    if ((!tab && tab !== 0) || !tab.searchBoxMap) {
      return;
    }
    // Get Search Request By Tab
    const searchRequestByTab = {};
    const formValueSave = {};
    tab.searchBoxMap.forEach((searchBox) => {
      if (searchBox.searchFields !== `${SEARCH_FIELD.CONTENTS_GROUP_SPECIAL.id}`) {
        searchRequestByTab[`search${searchBox.id}`] = {};
        if (searchBox.isInputSearch) {
          searchRequestByTab[`search${searchBox.id}`].value = formValue[`${tab.id}_search${searchBox.id}`];
          formValueSave[`${tab.id}_search${searchBox.id}`] = formValue[`${tab.id}_search${searchBox.id}`];
        } else {
          if (searchBox.idsMatchValueList && searchBox.idsMatchValueList.length > 0) {
            const newSearchRequests = [];
            const newSearchContentGroupRequests = [];
            for (const id of searchBox.idsMatchValueList) {
              if (formValue[id].checked) {
                if (formValue[id].isContentGroup) {
                  newSearchContentGroupRequests.push(formValue[id].value);
                } else {
                  newSearchRequests.push(formValue[id].value);
                }
                formValueSave[id] = formValue[id];
              }
            }
            searchRequestByTab[`search${searchBox.id}`].value = [...newSearchRequests].toString();
            searchRequestByTab[`search${searchBox.id}`].contentGroupIdList = [...newSearchContentGroupRequests].toString();
          } else {
            searchRequestByTab[`search${searchBox.id}`].value = null;
            searchRequestByTab[`search${searchBox.id}`].contentGroupIdList = null;
          }
        }
      }
    });

    if (tab.type === TAB_TYPE.FULLTEXT_SEARCH.id) {
      let searchValue;
      setIsFullTextSearch(true);
      for (const key in searchRequestByTab) {
        searchValue = searchRequestByTab[key];
      }
      const param = {
        value: searchValue,
        tabType: TAB_TYPE.FULLTEXT_SEARCH.id
      };
      onSearch(param, tab.id, formValue, formValueSave);
      setOpenSearchFullScreen(false);
    } else {
      setIsFullTextSearch(false);
      onSearch(searchRequestByTab, tab.id, formValue, formValueSave);
      setOpenSearchFullScreen(false);
    }
  };

  const handleSearchSpecialContentsGroup = (formValue, tab) => {
    if ((!tab && tab !== 0) || !tab.searchBoxMap) {
      return;
    }
    const pathname = window.location.pathname;
    // Get Search Request By Tab
    tab.searchBoxMap.forEach((searchBox) => {
      if (searchBox.searchFields === `${SEARCH_FIELD.CONTENTS_GROUP_SPECIAL.id}`) {
        if (searchBox.idsMatchValueList && searchBox.idsMatchValueList.length > 0) {
          for (const id of searchBox.idsMatchValueList) {
            if (formValue[id].checked) {
              const formValueSave = {};
              formValueSave[id] = formValue[id];
              setIsFullTextSearch(false);
              loadContentByContentGroupSpecial({
                contentGroupId: Number(formValue[id].value),
                page: 1,
                path: servicePath,
                size: PAGINATION.size
              });
              setInitialValues(formValueSave);
              setIdTabSearch(tab.id);
              setOpenSearchFullScreen(false);
              if (!pathname.includes('search') || contentId) {
                history.push('/search');
              }
              break;
            }
          }
        }
      }
    });
  };

  const handleClickChip = ({ id, value, checked, boxId, tabId, setFieldValue, values }) => {
    if (tabSearchMap.has(tabId)) {
      const newSearchBoxMap = tabSearchMap.get(tabId).searchBoxMap;
      if (newSearchBoxMap && newSearchBoxMap.has(boxId)) {
        if (newSearchBoxMap.get(boxId).isSingle) {
          for (const item of newSearchBoxMap.get(boxId).idsMatchValueList) {
            const newValue = { ...values[item] };
            if (item === id) {
              newValue.checked = !values[item].checked;
              setFieldValue(id, newValue);
              if (checked) {
                break;
              }
              continue;
            }
            if (values[item].checked) {
              newValue.checked = false;
              setFieldValue(item, newValue);
            }
          }
        } else {
          const newValue = { ...values[id] };
          newValue.checked = !values[id].checked;
          setFieldValue(id, newValue);
        }
      }
    }
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleChangeTab = (tab) => {
    toggle(tab.id);
    if (tab.type === TAB_TYPE.FULLTEXT_SEARCH.id) {
      setIsFullTextSearch(true);
    } else {
      setIsFullTextSearch(false);
    }
  };

  const renderSearchBoxs = (dataMap, values, setFieldValue) => {
    const result = [];
    if (dataMap && dataMap.size > 0) {
      dataMap.forEach((searchBox, key) => {
        if (searchBox.searchFields !== `${SEARCH_FIELD.CONTENTS_GROUP_SPECIAL.id}`) {
          result.push(
            <Fragment key={`search-boxs-${key}`}>
              {
                searchBox.isInputSearch
                  /* Render Input search field */
                  ? <Grid item sm={searchBox.size ? searchBox.size : 12} xs={12}>
                    {renderInputSearchBox(searchBox)}
                  </Grid>
                  /* Render Chip Select value */
                  : <>
                    <Grid item sm={searchBox.size ? searchBox.size : 12} xs={12} style={{ margin: '0.5rem 0' }}>
                      {
                        searchBox.isShowLabel &&
                        <label className={classes.titleSearchBox}>{searchBox.label}</label>
                      }
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        {
                          searchBox.idsMatchValueList.map((id, index) => (
                            <React.Fragment key={index}>
                              <Grid item className={`${classes.chip}`}>
                                {
                                  values[id].checked
                                    ? <Chip clickable={true} color='primary' onClick={() => { handleClickChip({ ...values[id], id, boxId: searchBox.id, tabId: searchBox.tabId, setFieldValue, values }) }} className={`${classes.chipActive}`} label={values[id].label} />
                                    : <Chip clickable={true} onClick={() => { handleClickChip({ ...values[id], id, boxId: searchBox.id, tabId: searchBox.tabId, setFieldValue, values }) }} variant="outlined" className={`${classes.chip}`} label={values[id].label} />
                                }
                              </Grid>
                            </React.Fragment>
                          ))
                        }
                      </Grid>
                    </Grid>
                  </>
              }
            </Fragment>
          );
        }
      });
    }
    return result;
  };

  const renderSearchFieldsSpecialContentsGroup = (dataMap, values, setFieldValue) => {
    const result = [];
    if (dataMap && dataMap.size > 0) {
      dataMap.forEach((searchBox, key) => {
        if (searchBox.searchFields === `${SEARCH_FIELD.CONTENTS_GROUP_SPECIAL.id}` && searchBox.contentGroupResponseList && searchBox.contentGroupResponseList.length > 0) {
          result.push(
            <Fragment key={`special-contents-group-${key}`}>
              <Grid item sm={searchBox.size ? searchBox.size : 12} xs={12} style={{ margin: '0.5rem 0' }}>
                {
                  searchBox.isShowLabel &&
                  <label className={classes.titleSearchBox}>{searchBox.label}</label>
                }
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center">
                  {
                    searchBox.idsMatchValueList.map((id, index) => (
                      <React.Fragment key={index}>
                        <Grid item className={`${classes.chip}`}>
                          {
                            values[id].checked
                              ? <Chip clickable={true} color='primary' onClick={() => { handleClickChip({ ...values[id], id, boxId: searchBox.id, tabId: searchBox.tabId, setFieldValue, values }) }} className={`${classes.chipActive}`} label={values[id].label} />
                              : <Chip clickable={true} onClick={() => { handleClickChip({ ...values[id], id, boxId: searchBox.id, tabId: searchBox.tabId, setFieldValue, values }) }} variant="outlined" className={`${classes.chip}`} label={values[id].label} />
                          }
                        </Grid>
                      </React.Fragment>
                    ))
                  }
                </Grid>
              </Grid>
            </Fragment>
          );
        }
      });
    }
    if (result.length > 0) {
      result.push(
        <Grid item md={12} xs={12} key={'special-contents-group-button'}>
          <div className={classes.boxSearchBottom}>
            <Button
              className={`${classes.btnSearch}`}
              onClick={() => { handleSearchSpecialContentsGroup(values, tabSearchMap.get(activeTab)) }}>
              {
                styleButtonSearch.isShowIcon && styleButtonSearch.icon && styleButtonSearch.icon !== '' &&
                  <span className={classes.iconButton}>
                    <i className={styleButtonSearch.icon}></i>
                  </span>
              }
              {
                styleButtonSearch.label && styleButtonSearch.label !== ''
                  ? <span>{styleButtonSearch.label}</span>
                  : <TranslateMessage id={'label.search'} />
              }
            </Button>
          </div>
        </Grid>
      );
    }
    return result;
  };

  const renderInputSearchBox = searchBox => {
    const label = searchBox.isShowLabel ? searchBox.label : null;
    switch (searchBox.operation) {
      case SEARCH_BOX_OPERATION.EXACT_MATCH.id:
      case SEARCH_BOX_OPERATION.PARTIAL_MATCH.id:
        return (
          <FastField
            component={InputField}
            name={`${searchBox.tabId}_search${searchBox.id}`}
            label={label}
            fontSize={'19px'}
            col={searchBox.size}
          />
        );
      case SEARCH_BOX_OPERATION.DATE_RANGE.id:
        return (
          <>
            {
              label && <label style={{ marginBottom: 0 }}>{label}</label>
            }
            <FastField
              component={InputDateRange}
              name={`${searchBox.tabId}_search${searchBox.id}`}
              // label={label}
              col={searchBox.size}
              format={'MM/dd/yyyy'}
              yearPlaceholder={'yyyy'}
              monthPlaceholder={'MM'}
              dayPlaceholder={'dd'}
            />
          </>
        );
      case SEARCH_BOX_OPERATION.MONTH_RANGE.id:
        return (
          <>
            {
              label && <label style={{ marginBottom: 0 }}>{label}</label>
            }
            <FastField
              component={InputDateRange}
              name={`${searchBox.tabId}_search${searchBox.id}`}
              // label={label}
              col={searchBox.size}
              format={'MM/yyyy'}
              minDetail={'decade'}
              maxDetail={'year'}
              yearPlaceholder={'yyyy'}
              monthPlaceholder={'MM'}
            />
          </>
        );
      case SEARCH_BOX_OPERATION.YEAR_RANGE.id:
        return (
          <>
            {
              label && <label style={{ marginBottom: 0 }}>{label}</label>
            }
            <FastField
              component={InputDateRange}
              name={`${searchBox.tabId}_search${searchBox.id}`}
              // label={label}
              col={searchBox.size}
              format={'yyyy'}
            />
          </>
        );
      default:
        return (
          <FastField
            component={InputField}
            name={`${searchBox.tabId}_search${searchBox.id}`}
            label={label}
            fontSize={'19px'}
            col={searchBox.size}
          />
        );
    }
  };

  const onSearch = (form, tabId, valueForm, formValueSave) => {
    let fulltextSearch = null;
    let searchRequest = [];
    const pagination = {
      page: 1,
      size: PAGINATION.size,
      ...searchQuery
    };
    if (form.tabType === TAB_TYPE.FULLTEXT_SEARCH.id) {
      fulltextSearch = form.value;
    } else {
      fulltextSearch = null;
      for (const item in form) {
        if (!form[item]) {
          break;
        }
        let value = form[item].value;
        const contentGroupIdList = form[item].contentGroupIdList || '';

        if (Array.isArray(value) && value.length > 0) {
          let str = '';
          for (let item of value) {
            if (!item) {
              item = '';
            }
            if (item && item.value) {
              str += `${str === '' ? '' : ','}${item.value}`;
            } else {
              str += `${str === '' ? '' : ','}${item === '' ? '' : Util.formatDate(item, 'YYYY/MM/DD')}`;
            }
          }
          value = str;
        }
        if ((value && value !== '' && value.length !== 0) || contentGroupIdList) {
          const param = {
            value: value || '',
            searchBoxId: parseInt(item.replace('search', ''), 10)
          };
          if (contentGroupIdList) {
            param.contentGroupIdList = contentGroupIdList.split(',').map(item => Number(item));
          }
          searchRequest = [...searchRequest, param];
        }
      }
    }
    const isScrollPage = searchSetting.searchResultPagingType === PAGING_TYPE.SCROLL;
    // Search
    if (isLoggedIn) {
      if (fulltextSearch) {
        searchFullText({
          searchKey: fulltextSearch,
          pagination,
          isScrollPage
        });
      } else {
        const params = {
          searchRequests: searchRequest,
          path: servicePath,
          pagination,
          isScrollPage
        };
        getListContent(params);
      }
    } else {
      if (fulltextSearch) {
        searchFullText({
          searchKey: fulltextSearch,
          pagination,
          isScrollPage
        });
      } else {
        const params = {
          searchRequests: searchRequest,
          path: servicePath,
          pagination,
          isScrollPage
        };
        getListPublicContent(params);
      }
    }
    setSpecialContentsGroupId(null);
    const pathname = window.location.pathname;
    setInitialValues(formValueSave);
    // setTabSearchMapStore(tabSearchMap);
    setIdTabSearch(tabId);
    if (!pathname.includes('search') || contentId) {
      history.push('/search');
      // StorageHelper.setSessionObject(STORAGE_KEYS.searchContent, { initialValues: valueForm, searchRequest, tabId });
    }
  };

  const handleCloseIconClick = (values) => {
    setOpenSearchFullScreen(false);
  };

  useEffect(() => {
    if (tabs && tabs.length > 0 && IsOpenSearchFullScreen) {
      const newTabsMap = new Map();
      var newInitValue = { ...initialValues };
      var inputSearchBoxDate = [];
      const isScrollPage = searchSetting.searchResultPagingType === PAGING_TYPE.SCROLL;
      // Tabs
      for (const i in tabs) {
        const newSearchBoxMap = new Map();
        const searchBoxList = [...tabs[i].searchBoxes];
        // Search Box
        for (const searchBox of searchBoxList) {
          var isEmptyMatchValue = false;
          var data = null;
          const init = {};
          if (SEARCH_BOX_OPERATION.MULTIPART_CHOICE.id === searchBox.operation || SEARCH_BOX_OPERATION.SINGLE_CHOICE.id === searchBox.operation) {
            // Choice Value
            const isSingle = SEARCH_BOX_OPERATION.SINGLE_CHOICE.id === searchBox.operation;
            // get match Value
            const list = searchBox.matchValue ? searchBox.matchValue.split(',') : [];
            const contentGroupResponseList = searchBox.contentGroupResponseList || [];
            // check value in queriesSearch
            const idsMatchValueList = [];
            for (const index in list) {
              if (list[index] !== '') {
                const id = `${tabs[i].id}_search${searchBox.id}#${index}`;
                idsMatchValueList.push(id);
                if (!initialValues[id] || !initialValues[id].value || initialValues[id].value === '') {
                  init[id] = {
                    value: list[index],
                    label: list[index],
                    isContentGroup: false,
                    isContentGroupSpecial: false,
                    isScrollPage,
                    checked: false
                  };
                }
              }
            }
            for (const index in contentGroupResponseList) {
              if (contentGroupResponseList[index] && (isLoggedIn || (contentGroupResponseList[index].type !== CONTENT_GROUP_TYPE.FAVORITE && contentGroupResponseList[index].type !== CONTENT_GROUP_TYPE.RECENTLY))) {
                const id = `${tabs[i].id}_search${searchBox.id}#${list.length + index}`;
                idsMatchValueList.push(id);
                if (!initialValues[id] || !initialValues[id].value || initialValues[id].value === '') {
                  init[id] = {
                    value: contentGroupResponseList[index].id,
                    label: contentGroupResponseList[index].name,
                    isContentGroup: true,
                    isContentGroupSpecial: searchBox.searchFields === `${SEARCH_FIELD.CONTENTS_GROUP_SPECIAL.id}`,
                    checked: false,
                    type: contentGroupResponseList[index].type
                  };
                }
              }
            }
            isEmptyMatchValue = idsMatchValueList.length === 0;
            data = {
              ...searchBox,
              tabId: tabs[i].id,
              idsMatchValueList: idsMatchValueList,
              isSingle
            };
          } else {
            // Input Search
            data = {
              ...searchBox,
              tabId: tabs[i].id,
              isInputSearch: true
            };
          }
          if (!isEmptyMatchValue) {
            newSearchBoxMap.set(searchBox.id, data);
          }

          // Set Initial Values
          switch (searchBox.operation) {
            case SEARCH_BOX_OPERATION.EXACT_MATCH.id:
            case SEARCH_BOX_OPERATION.PARTIAL_MATCH.id:
              init[`${tabs[i].id}_search${searchBox.id}`] = initialValues[`${tabs[i].id}_search${searchBox.id}`] || '';
              break;
            case SEARCH_BOX_OPERATION.MULTIPART_CHOICE.id:
              init[`${tabs[i].id}_search${searchBox.id}`] = initialValues[`${tabs[i].id}_search${searchBox.id}`] || [];
              break;
            case SEARCH_BOX_OPERATION.DATE_RANGE.id:
            case SEARCH_BOX_OPERATION.SINGLE_CHOICE.id:
            case SEARCH_BOX_OPERATION.MONTH_RANGE.id:
              init[`${tabs[i].id}_search${searchBox.id}`] = initialValues[`${tabs[i].id}_search${searchBox.id}`] || null;
              break;
            case SEARCH_BOX_OPERATION.YEAR_RANGE.id:
              init[`${tabs[i].id}_search${searchBox.id}`] = initialValues[`${tabs[i].id}_search${searchBox.id}`] || [null, null];
              inputSearchBoxDate.push(`${tabs[i].id}_search${searchBox.id}`);
              break;
            default:
              init[`${tabs[i].id}_search${searchBox.id}`] = initialValues[`${tabs[i].id}_search${searchBox.id}`] || '';
              break;
          }
          newInitValue = { ...newInitValue, ...init };
        }
        if (newSearchBoxMap.size > 0) {
          newTabsMap.set(tabs[i].id, { ...tabs[i], searchBoxMap: newSearchBoxMap });
        }
      }
      setInitialValuesDefault(newInitValue);
      setTabSearchMap(newTabsMap);
      // setTabSearchMapStore(newTabsMap);
      setActiveTab(idTabActive || idTabActive === 0 ? idTabActive : tabs[0].id);
    }
  }, [tabs, IsOpenSearchFullScreen]);

  return (
    <div>
      <div className={classes.containerMenu} onClick={toggleDrawer(true)}>
        <Menu titleAccess={f({ id: 'label.search' })}/>
        <div>{label}</div>
      </div>
      <Drawer anchor={'top'} open={IsOpenSearchFullScreen} onClose={toggleDrawer(false)} style={{ position: 'relative', height: '100%' }}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValuesDefault}
        >
          {({ values, setFieldValue }) => {
            var hasNormalSearchBox = false;
            tabSearchMap.get(activeTab).searchBoxMap.forEach((searchBox) => {
              if (searchBox.searchFields !== `${SEARCH_FIELD.CONTENTS_GROUP_SPECIAL.id}`) {
                hasNormalSearchBox = true;
              }
            });
            return (
              <Form style={{ position: 'relative', height: '100%', width: '100%' }}>
                <CloseIcon className={classes.closeIcon} onClick={() => handleCloseIconClick(values)}/>
                <div className={classes.paperRoot}>
                  <div className={classes.containerPaper}>
                    <Grid
                      className={classes.containerSearchKey}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {
                        tabs.length > 1 &&
                        <Nav tabs pills style={{ borderBottom: 0, width: '100%', marginBottom: '2rem' }}>
                          {tabs.length > 0 && tabs.map(tab => {
                            if (tabSearchMap.has(tab.id)) {
                              return (
                                <NavItem className={`${classes.navItem} ${activeTab === tab.id && classes.activeNav}`} style={{ margin: '5px 3px 0 3px' }} key={`nav-${tab.id}`}>
                                  <NavLink
                                    className={`${classes.navlink}`}
                                    onClick={() => handleChangeTab(tab)}
                                    active={activeTab === tab.id}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {tab.title}
                                  </NavLink>
                                </NavItem>
                              );
                            }
                          })}
                        </Nav>
                      }
                      { // renderSearchTab
                        (activeTab && tabSearchMap.has(activeTab)) &&
                        <Fragment>
                          {
                            tabs.length === 1 &&
                            <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                              <h3 className={classes.titleSearchBox}>{tabSearchMap.get(activeTab).title}</h3>
                            </Grid>
                          }
                          <Grid item xs={12}>
                            <Grid
                              // className={classes.gridSearchBox}
                              container
                              spacing={2}
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              {renderSearchBoxs(tabSearchMap.get(activeTab).searchBoxMap, values, setFieldValue)}
                              {/* Render Button Search */}
                              {
                                hasNormalSearchBox &&
                                <Grid item md={12} xs={12}>
                                  <div className={classes.boxSearchBottom}>
                                    <Button className={`${classes.btnSearch}`} onClick={() => { handleSearch(values, tabSearchMap.get(activeTab)) }}>
                                      {
                                        styleButtonSearch.isShowIcon && styleButtonSearch.icon && styleButtonSearch.icon !== '' &&
                                          <span className={classes.iconButton}>
                                            <i className={styleButtonSearch.icon}></i>
                                          </span>
                                      }
                                      {
                                        styleButtonSearch.label && styleButtonSearch.label !== ''
                                          ? <span>{styleButtonSearch.label}</span>
                                          : <TranslateMessage id={'label.search'} />
                                      }
                                    </Button>
                                  </div>
                                </Grid>
                              }
                              {/* Render Special Contents Group Search Fields */}
                              {renderSearchFieldsSpecialContentsGroup(tabSearchMap.get(activeTab).searchBoxMap, values, setFieldValue)}
                            </Grid>
                          </Grid>
                        </Fragment>
                      }
                    </Grid>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Drawer>
    </div>
  );
};

IconMenuBarButton.propTypes = {
  label: PropTypes.any,

  searchActions: PropTypes.any,
  homeActions: PropTypes.any,
  tabs: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  servicePath: PropTypes.any,
  layoutSetting: PropTypes.object,
  searchSetting: PropTypes.object,
  pageMargin: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    tabs: getTabs(state),
    servicePath: getServicePath(state),
    layoutSetting: getLayoutSetting(state),
    searchSetting: getSearchSetting(state),
    isLoggedIn: isAuthenticated(state),
    pageMargin: getPageMargin(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ searchActions, homeActions }) => (
        <IconMenuBarButton
          {...props}
          searchActions={searchActions}
          homeActions={homeActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
