import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import {
  withRouter,
  Switch, Route,
  Redirect,
  BrowserRouter as Router,
  useHistory,
  useLocation
} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import PropTypes from 'prop-types';

import * as ErrorActions from 'state/handler/ErrorActions';
import * as AccountActions from 'state/account/AccountActions';
import * as LayoutActions from 'state/layout/LayoutActions';
import * as SessionActions from 'state/session/SessionActions';
import * as LocaleActions from 'state/locale/LocaleActions';
import * as RouteActions from 'state/route/RouteActions';
import * as NotificationActions from 'state/notification/NotificationActions';
import * as ScrollbarActions from 'state/scrollbar/scrollbarActions';
import * as ContentActions from 'state/content/ContentActions';
import * as CompanyActions from 'state/company/CompanyActions';
import * as SearchActions from 'state/search/SearchActions';
import * as ButtonActions from 'state/button/ButtonActions';
import * as BannerActions from 'state/banner/BannerActions';
import * as SortActions from 'state/sort/SortActions';
import * as TemplateActions from 'state/template/TemplateActions';
import * as InputActions from 'state/input/InputActions';
import * as WatermarkActions from 'state/watermark/WatermarkActions';
import * as HomeActions from 'state/home/HomeActions';
import * as ContentDetailActions from 'state/content-detail/ContentDetailActions';
import * as CustomLoginActions from 'state/login/LoginActions';
import * as SubscriptionActions from 'state/subscription/SubscriptionActions';
import * as CouponActions from 'state/coupon/CouponActions';
import * as PaymentActions from 'state/payment/PaymentActions';
import * as SubscriptionOrdinalActions from 'state/subscription-ordinal/SubscriptionOrdinalActions';
import * as TransactionActions from 'state/transaction/TransactionActions';
import * as NotificationsActions from 'state/notifications/NotificationsActions';

import createRoutes, { NoMatchPage } from 'configs/routes';
import { DOMAIN_TYPE, IGNORE_SERVICE_PATH, LAYOUT_SETTING, MAIN_LAYOUT, OPTION_LOGIN, PUBLIC_PAGE, PaymentMethod, STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';
import { getLayoutRoute } from 'state/route/routeSelector';
import NotificationStyle from 'assets/styles/NotificationStyle';

import AppTitle from 'components/common/Title/AppTitle';
import ErrorBoundary from './ErrorBoundary';
import { makeStyles } from '@material-ui/styles';
import {
  getAccountType,
  getCompanyName, getGoogleId, getIsLoadSetting, getLayoutViewPage, getPageTitle
} from 'state/search/searchSelector';
import { getServicePath, getViewSettings } from 'state/company/companySelector';
import { Spinner } from 'reactstrap';
import { Grid } from '@material-ui/core';
import SearchViewBook from 'components/search/SearchViewBook';
import ViewOnlineLayout from './ViewOnlineLayout';
import PublicDetailContent from 'views/end-user/PublicDetailContent';

import { getAuthUser, isGoogleLogin } from 'state/session/sessionSelector';
import LayoutWrapRoute from './LayoutWrapRoute';
import NoLayoutRoute from './NoLayoutRoute';
import GakkenPaymentError from 'components/page/GakkenPaymentError';
import StorageHelper from 'utils/StorageHelper';
import TagManager from 'react-gtm-module';
// import StorageHelper from 'utils/StorageHelper';

export const ActionContext = React.createContext(null);
export const SessionContext = React.createContext(null);
export const LayoutContext = React.createContext(null);
export const RouterContext = React.createContext(null);
export const LocaleContext = React.createContext(null);

const Multipayment = window.Multipayment;

const MainContainerStyle = makeStyles(theme => (
  {
    root: {
      backgroundColor: '#ffffff',
      width: '100%',
      height: '100%',
      margin: 0
    }
  }
));

const MainContainer = props => {
  const {
    actions,
    session,
    locale,
    layout,
    match,
    location,
    viewSetting,
    intl,
    route: { routeTitle, routePath },
    error,
    accountType,
    actions: {
      errorActions: { setError },
      localeActions: { setIntl },
      routeActions: { setHistory },
      notificationActions: { setupNotification },
      scrollbarActions: { scrollToTop },
      sessionActions: { logout, getAuthInfo },
      companyActions: { setCompanyDomain, loadSetting }
    }
    // companyName
    // titlePage
  } = props;
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const userIdSearch = searchParams.get('UserID');

  const [isValidServicePath, setIsValidServicePath] = useState(false);
  const [titleName, setTitleName] = useState('');
  const showSubscriptionStatus = useSelector((state) => state.company.showSubscriptionStatus);
  const isLoadSetting = useSelector(getIsLoadSetting);
  const layoutViewPage = useSelector(getLayoutViewPage);
  const authUser = useSelector(getAuthUser);
  const isLayout3 = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;
  const appMobileAuthToken = StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken);
  const currentServicePath = StorageHelper.getSessionItem(STORAGE_KEYS.path);
  const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
  const userStore = !currentServicePath || currentServicePath === '' ? currentDomain : currentServicePath;
  const token = StorageHelper.getCookie(userStore);
  // const isSession = useSelector(isSessionValidating);

  const history = useHistory();

  const router = { match, location, history };

  const isNoLayout = layoutViewPage === LAYOUT_SETTING.NO_LAYOUT.id;
  const isFullDomain = viewSetting.domainType === DOMAIN_TYPE.FULL_DOMAIN;

  const path = window.location.pathname.split('/')[1];
  const servicePath = isFullDomain
    ? ''
    : IGNORE_SERVICE_PATH.includes(path)
      ? ''
      : path;
  const hostname = window.location.hostname;
  const isPublicViewer = PUBLIC_PAGE.find(item => item === servicePath);
  const listDomainSystemAdmin = process.env.REACT_APP_DOMAIN_SYSTEM_ADMIN.split(',');

  const isGMOPayment = viewSetting.paymentGateway === PaymentMethod.GMO;

  const subRoute = createRoutes().filter(item => {
    if (showSubscriptionStatus) {
      return true;
    } else {
      return !item.showWithSubscription;
    }
  });

  const classes = MainContainerStyle();

  useEffect(() => {
    if (isLayout3) {
      document.body.style.fontFamily = 'Noto Sans JP';
    }
  }, [isLayout3]);

  useEffect(() => {
    setIntl(intl);
    setHistory(history);
    if (listDomainSystemAdmin.indexOf(hostname) > -1) {
      window.open(window.location.origin + '/admin/login', '_self');
    } else {
      if (servicePath !== 'admin') {
        setCompanyDomain(hostname);
        loadSetting({ domain: hostname, path: isPublicViewer ? null : servicePath });
      } else {
        window.open(window.location.origin + '/admin/login', '_self');
      }
    }

    if (window.location.pathname !== routePath) {
      // scroll to top
      scrollToTop && scrollToTop(MAIN_LAYOUT);

      // clear error boundary to render normal page after redirect from crashed page
      error.errorInfo && setError(undefined, undefined);
    }
  }, []);

  useEffect(() => {
    if (listDomainSystemAdmin.indexOf(hostname) < 0) {
      if (viewSetting && viewSetting.paths) {
        if (viewSetting.paths.includes(servicePath) || isPublicViewer || isFullDomain) {
          setIsValidServicePath(true);
        } else {
          if (servicePath === 'admin') {
            window.open(`${window.location.href}`, '_self');
          } else {
            setTimeout(() => {
              history.push('/404');
            }, 1000);
          }
        }
      }
    }
  }, [viewSetting, servicePath]);

  useEffect(() => {
    if (authUser && authUser.id) {
      if (authUser.roleLevel !== USER_CONFIG.USER.roleLevel && authUser.roleLevel !== USER_CONFIG.IP.roleLevel) {
        navigateAdminPage();
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser && authUser.shopId && isGMOPayment && Multipayment) {
      Multipayment.init(authUser.shopId);
    }
    if (authUser && authUser.path && viewSetting && viewSetting.domainType) {
      const isValidDomain = isFullDomain
        ? authUser.path === hostname
        : authUser.path === servicePath && hostname === authUser.domain;

      if (!isValidDomain) {
        logout();
      }
    }
  }, [authUser, viewSetting, Multipayment]);

  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-NLXGFLRH'
  //   };
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  useEffect(() => {
    if (viewSetting.serviceName) {
      if (viewSetting.titlePage) {
        setTitleName(viewSetting.titlePage);
      } else {
        setTitleName(viewSetting.serviceName);
      }
      if (viewSetting.gtmId && viewSetting.gtmId !== '') {
        TagManager.initialize({ gtmId: viewSetting.gtmId });
      }
    }
  }, [viewSetting]);

  useEffect(() => {
    // when userIdSearch !==  token
    if (viewSetting && viewSetting.isEnableWebMobile && accountType === OPTION_LOGIN.GAKKEN_ID && userIdSearch && userIdSearch !== '' && token !== userIdSearch) {
      StorageHelper.setLocalItem(STORAGE_KEYS.appMobileAuthToken, true);
      StorageHelper.setCookie(userStore, userIdSearch, {
        maxAge: 60 * 60 * 24 * 365
      });
      getAuthInfo();
    }
    if (viewSetting.isEnableWebMobile === false && token && appMobileAuthToken) {
      StorageHelper.removeLocalItem(STORAGE_KEYS.appMobileAuthToken);
      StorageHelper.removeCookie(userStore);
      logout();
    }
  }, [userIdSearch, appMobileAuthToken, accountType, viewSetting]);

  const setNotificationRef = ref => {
    setupNotification(ref);
  };
  const navigateAdminPage = () => {
    logout();
    window.open(window.location.origin + '/admin/login', '_self');
  };

  return (
    <RouterContext.Provider value={router}>
      <ActionContext.Provider value={actions}>
        <SessionContext.Provider value={session}>
          <LocaleContext.Provider value={locale}>
            <LayoutContext.Provider value={layout}>
              {/* <React.StrictMode> */}
              <AppTitle intl={intl} routeTitle={routeTitle} companyName={titleName} />
              <ErrorBoundary error={error} setError={setError}>
                {
                  !isLoadSetting
                    ? <div className={classes.root}>
                      {
                        viewSetting && isValidServicePath
                          ? <Switch>
                            <Route
                              path="/view-online/:id"
                              render={
                                () => <ViewOnlineLayout location={location}>
                                  <SearchViewBook />
                                </ViewOnlineLayout>
                              }
                            />
                            <Route
                              path="/content/:contentToken"
                              render={
                                () => <PublicDetailContent/>
                              }
                            />
                            <Route
                              path="/404"
                              render={
                                () => <NoMatchPage/>
                              }
                            />
                            <Route
                              path={`/${servicePath}`}
                            >
                              <Router basename={`/${servicePath}`}>
                                {
                                  isNoLayout
                                    ? <NoLayoutRoute/>
                                    : <LayoutWrapRoute routes={subRoute} />
                                }
                              </Router>
                            </Route>
                            <Route
                              path="*"
                            >
                              <Redirect to="/404" push />
                            </Route>
                          </Switch>
                          : <Switch>
                            <Route
                              path="/gakken-payment/error"
                              render={
                                () => <GakkenPaymentError/>
                              }
                            />
                            <Route
                              path="/404"
                              render={
                                () => <NoMatchPage/>
                              }
                            />
                          </Switch>
                      }
                      <NotificationSystem
                        ref={setNotificationRef}
                        style={NotificationStyle}
                      />
                    </div>
                    : <Grid
                      container
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
                    >
                      <Spinner color="primary" style={{ marginRight: 20 }} />
                    </Grid>
                }
              </ErrorBoundary>
              {/* </React.StrictMode> */}
            </LayoutContext.Provider>
          </LocaleContext.Provider>
        </SessionContext.Provider>
      </ActionContext.Provider>
    </RouterContext.Provider>
  );
};

MainContainer.defaultProps = {};

MainContainer.propTypes = {
  actions: PropTypes.any,
  session: PropTypes.any,
  locale: PropTypes.any,
  layout: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  LayoutRoute: PropTypes.any,
  intl: PropTypes.any,
  error: PropTypes.any,
  route: PropTypes.any,
  viewSetting: PropTypes.any,
  companyDomain: PropTypes.any,
  companyName: PropTypes.any,
  serviceName: PropTypes.any,
  isGoogleLogin: PropTypes.bool,
  googleId: PropTypes.any,
  titlePage: PropTypes.string,
  accountType: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    error: state.error,
    layout: state.layout,
    session: state.session,
    route: state.route,
    LayoutRoute: getLayoutRoute(state),
    locale: state.locale,
    viewSetting: getViewSettings(state),
    companyName: getCompanyName(state),
    serviceName: getServicePath(state),
    isGoogleLogin: isGoogleLogin(state),
    googleId: getGoogleId(state),
    accountType: getAccountType(state),
    titlePage: getPageTitle(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      errorActions: bindActionCreators(ErrorActions, dispatch),
      layoutActions: bindActionCreators(LayoutActions, dispatch),
      accountActions: bindActionCreators(AccountActions, dispatch),
      sessionActions: bindActionCreators(SessionActions, dispatch),
      localeActions: bindActionCreators(LocaleActions, dispatch),
      routeActions: bindActionCreators(RouteActions, dispatch),
      notificationActions: bindActionCreators(NotificationActions, dispatch),
      scrollbarActions: bindActionCreators(ScrollbarActions, dispatch),
      contentActions: bindActionCreators(ContentActions, dispatch),
      companyActions: bindActionCreators(CompanyActions, dispatch),
      searchActions: bindActionCreators(SearchActions, dispatch),
      buttonActions: bindActionCreators(ButtonActions, dispatch),
      bannerActions: bindActionCreators(BannerActions, dispatch),
      sortActions: bindActionCreators(SortActions, dispatch),
      templateActions: bindActionCreators(TemplateActions, dispatch),
      inputActions: bindActionCreators(InputActions, dispatch),
      homeActions: bindActionCreators(HomeActions, dispatch),
      customLoginActions: bindActionCreators(CustomLoginActions, dispatch),
      watermarkActions: bindActionCreators(WatermarkActions, dispatch),
      contentDetailActions: bindActionCreators(ContentDetailActions, dispatch),
      subscriptionActions: bindActionCreators(SubscriptionActions, dispatch),
      couponActions: bindActionCreators(CouponActions, dispatch),
      paymentActions: bindActionCreators(PaymentActions, dispatch),
      subscriptionOrdinalActions: bindActionCreators(SubscriptionOrdinalActions, dispatch),
      transactionActions: bindActionCreators(TransactionActions, dispatch),
      notificationsActions: bindActionCreators(NotificationsActions, dispatch)
    }
  };
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MainContainer)
  )
);
