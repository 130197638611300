import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import TranslateMessage from '../TranslateMessage/TranslateMessage';
import { makeStyles } from '@material-ui/core/styles';
import './index.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';

const InputDateRange = (props) => {
  const {
    field, form, options,
    label, placeholder, disabled,
    col, errorMessage, isTouched,
    format, minDetail, maxDetail,
    yearPlaceholder, monthPlaceholder, dayPlaceholder
  } = props;
  const { name, value } = field;
  const { errors, touched, submitCount } = form;
  let showError = errors[name] && touched[name];

  const inputSetting = useSelector((state) => state.search.inputSetting) || {};

  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);

  const useStyle = makeStyles((theme) => ({
    unPaddingTop: {
      paddingTop: 0
    },
    input: {
      margin: '0.5rem 0',
      '& .react-daterange-picker__wrapper': {
        fontSize: inputSetting.fontSize ? `${inputSetting.fontSize}px!important` : '',
        height: inputSetting.height ? `${inputSetting.height}px!important` : '38px',
        borderColor: `${inputSetting.borderColor ? inputSetting.borderColor : '#ced4da'}!important`,
        borderRadius: 'unset!important'
      },
      '& .react-daterange-picker__inputGroup': {
        overflow: 'hidden'
      }
    },
    border: {
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: 'unset',
      margin: '0.5rem 0',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      borderColor: `${inputSetting.borderColor ? inputSetting.borderColor : '#ced4da'}!important`,
      '& .react-datepicker-wrapper input': {
        fontSize: inputSetting.fontSize ? `${inputSetting.fontSize}px!important` : '',
        height: inputSetting.height ? `${inputSetting.height - 2}px!important` : '36px'
      },
      '& .react-datepicker-wrapper': {
        flex: '1 0 0px'
      }
    },
    clearButton: {
      cursor: 'pointer',
      '&:hover': {
        color: '#0078d7'
      }
    },
    inputBox: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0!important',
      '& label': {
        minWidth: 100,
        paddingRight: '0.5rem',
        margin: 0
      }
    },
    colInput: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    inputFormat: {
      '& .react-daterange-picker__wrapper': {
        overflow: 'hidden'
      },
      '& .react-daterange-picker__inputGroup': {
        overflow: 'hidden'
      },
      '& .react-daterange-picker': {
        position: 'unset'
      }
    }
  }));

  const classes = useStyle();

  if (errorMessage) {
    showError = !!errorMessage && isTouched;
  }

  useEffect(() => {
    if (format === 'yyyy' && value) {
      if (!startYear && endYear) {
        onChangeStartYear(endYear);
      } else if (startYear && !endYear) {
        onChangeEndYear(startYear);
      }
    }
  }, [submitCount]);

  const onChangeDate = (date) => {
    const changeEvent = {
      target: {
        name: name,
        value: date
      }
    };
    field.onChange(changeEvent);
  };

  const onChangeStartYear = (year = '') => {
    const value = [year, endYear || ''];
    setStartYear(year);

    const changeEvent = {
      target: {
        name: name,
        value: value
      }
    };
    field.onChange(changeEvent);
  };

  const onChangeEndYear = (year) => {
    const parseYear = year ? new Date(year.getFullYear(), 11, 31) : '';
    const value = [startYear || '', parseYear];
    setEndYear(parseYear);

    const changeEvent = {
      target: {
        name: name,
        value: value
      }
    };
    field.onChange(changeEvent);
  };

  const clearYearPicker = () => {
    setStartYear(null);
    setEndYear(null);

    const changeEvent = {
      target: {
        name: name,
        value: ['', '']
      }
    };
    field.onChange(changeEvent);
  };

  useEffect(() => {
    if (format === 'yyyy' && value && value.length > 0 && !startYear && !endYear) {
      if (value[0]) {
        setStartYear(new Date(value[0]));
      }
      if (value[1]) {
        setEndYear(new Date(value[1]));
      }
    }
  }, []);

  return (
    <FormGroup className={`${classes.inputBox} ${col !== 12 && classes.colInput}`}>
      {label && <Label
        for={name}
        className={classes.unPaddingTop}
      >{label}</Label>}
      {
        format === 'yyyy'
          ? <div className={`d-flex ${classes.border}`}>
            <DatePicker
              id={`${name}-from-${(new Date()).getTime()}`}
              {...field}
              selected={startYear}
              onChange={date => onChangeStartYear(date)}
              showYearPicker
              dateFormat='yyyy'
              className={'form-control shadow-none border-0 text-center'}
              placeholderText={'yyyy'}
              maxDate={endYear}
            />
            <span className={'d-flex align-items-center'}>–</span>
            <DatePicker
              id={`${name}-to-${(new Date()).getTime()}`}
              selected={endYear}
              onChange={date => onChangeEndYear(date)}
              showYearPicker
              dateFormat='yyyy'
              className={'form-control shadow-none border-0 text-center'}
              placeholderText={'yyyy'}
              minDate={startYear}
            />
            <span
              className={`d-flex align-items-center mr-2 ${classes.clearButton}`}
              onClick={() => clearYearPicker()}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' stroke='black' strokeWidth='2' className='react-daterange-picker__clear-button__icon react-daterange-picker__button__icon'><line x1='4' x2='15' y1='4' y2='15'></line><line x1='15' x2='4' y1='4' y2='15'></line></svg>
            </span>
          </div>
          : <DateRangePicker
            id={`${name}-${(new Date()).getTime()}`}
            {...field}
            onChange={onChangeDate}
            placeholder={placeholder}
            isDisabled={disabled}
            options={options}
            format={format}
            minDetail={minDetail}
            maxDetail={maxDetail}
            yearPlaceholder={yearPlaceholder}
            monthPlaceholder={monthPlaceholder}
            dayPlaceholder={dayPlaceholder}
            className={`${showError ? 'is-invalid' : ''} ${classes.input} ${classes.inputFormat}`}
            calendarIcon={null}
          />
      }
      {!errorMessage && (
        <ErrorMessage name={name} component={FormFeedback} style={{
          display: showError ? 'inline' : 'none'
        }} />
      )}
      {!!errorMessage && (
        <FormFeedback style={{
          display: showError ? 'inline' : 'none'
        }}>
          <TranslateMessage id={errorMessage} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};

InputDateRange.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,

  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  col: PropTypes.number,
  onSelected: PropTypes.func,
  errorMessage: PropTypes.any,
  isTouched: PropTypes.any,
  classes: PropTypes.any,
  format: PropTypes.any,
  minDetail: PropTypes.any,
  maxDetail: PropTypes.any,
  yearPlaceholder: PropTypes.any,
  monthPlaceholder: PropTypes.any,
  dayPlaceholder: PropTypes.any
};

InputDateRange.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  options: [],
  onSelected: null
};

export default InputDateRange;
