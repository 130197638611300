import SessionTypes from './SessionTypes';
import StorageHelper from 'utils/StorageHelper';
import { STORAGE_KEYS } from 'configs/AppConfig';

const initialState = {
  isAuthenticated: false,
  isSessionValidating: false, // for rendering flash screen
  isGoogleLogin: false,
  isMicrosoftLogin: false,
  isCallStartAppWithCustomAuth: false,
  isMobileApp: !!StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken)
};

export default function sessionReducer (state = initialState, action) {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case SessionTypes.RESTORE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: true,
        isSessionValidating: false,
        user: payload,
        isMobileApp: !!StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken)
      });
    case SessionTypes.LOG_IN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        showGakkenTermsAllow: false,
        showGakkenMypage: false
      });
    case SessionTypes.LOG_IN_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: true,
        isSessionValidating: false,
        authToken: payload.accessToken,
        user: payload.user,
        isGoogleLogin: StorageHelper.getLocalObject(STORAGE_KEYS.googleLogin) ? StorageHelper.getLocalObject(STORAGE_KEYS.googleLogin).isGoogleLogin : payload.isGoogleLogin,
        isMicrosoftLogin: StorageHelper.getLocalObject(STORAGE_KEYS.microsoftLogin) ? StorageHelper.getLocalObject(STORAGE_KEYS.microsoftLogin).isMicrosoftLogin : payload.isMicrosoftLogin,
        isLoading: false,
        showGakkenTermsAllow: false,
        showGakkenMypage: false,
        isMobileApp: !!payload.isMobileApp
      });
    case SessionTypes.ALLOW_GAKKEN_TERMS_REQUEST:
      return Object.assign({}, state, {
        showGakkenTermsAllow: true,
        isLoading: false
      });
    case SessionTypes.SHOW_GAKKEN_MY_PAGE:
      return Object.assign({}, state, {
        showGakkenMypage: true
      });
    case SessionTypes.RESTORE_SESSION_FAILURE:
    case SessionTypes.REAUTHORIZE:
    case SessionTypes.LOG_IN_ERROR:
    case SessionTypes.LOG_OUT_SUCCESS:
    case SessionTypes.CLEAR_AUTH:
      return Object.assign({}, state,
        {
          isAuthenticated: false,
          isSessionValidating: false,
          isGoogleLogin: false,
          isMicrosoftLogin: false,
          isLoading: false,
          authToken: '',
          user: {},
          showGakkenTermsAllow: false,
          showGakkenMypage: false,
          isMobileApp: !!StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken)
        }
      );
    case SessionTypes.CALL_START_APP_WITH_CUSTOM_AUTH:
      return Object.assign({}, state, {
        isCallStartAppWithCustomAuth: payload
      });
    default:
      newState = state;
  }

  return newState;
}
