import { all, put, select, takeEvery } from 'redux-saga/effects';
import HomeTypes from './HomeTypes';
import createRequest from '../../api/httpRequest';
import API_CONTENT_GROUP from '../../api/content-group';
import API_CONTENT from '../../api/content';
import API_SEARCH from '../../api/search';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain, getServicePath } from '../company/companySelector';
import { getIsMobileApp, isAuthenticated } from '../session/sessionSelector';
import { TYPE_PRE_NEXT_PAGE } from 'configs/AppConfig';

function * loadPublicContentGroups () {
  yield takeEvery(HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST, _loadPublicContentGroups);
}

function * _loadPublicContentGroups ({ payload = {} }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const { contentId } = payload;
  const request = snakecaseKeys({ ...payload, domain, path: servicePath });
  const isAuthen = yield select(isAuthenticated);
  const api = isAuthen
    ? API_CONTENT_GROUP.GET_USER_CONTENT_GROUPS
    : contentId
      ? API_CONTENT_GROUP.GET_PUBLIC_CONTENT_GROUPS_BY_CONTENTID
      : API_CONTENT_GROUP.GET_PUBLIC_CONTENT_GROUPS;
  yield createRequest({
    api: {
      ...api,
      params: { ...request }
    },
    onSuccess: function * (response) {
      contentId
        ? yield put({
          type: HomeTypes.GET_CONTENT_GROUP_BY_CONTENT_ID_SUCCESS,
          payload: Util.toCamelCaseKey(response.data)
        })
        : yield put({
          type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_SUCCESS,
          payload: Util.toCamelCaseKey(response.data)
        });
    },
    onError: function * ({ status }) {
      contentId
        ? yield put({
          type: HomeTypes.GET_CONTENT_GROUP_BY_CONTENT_ID_ERROR,
          payload: status
        })
        : yield put({
          type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_ERROR,
          payload: status
        });
    }
  });
}

function * loadContentByContentGroup () {
  yield takeEvery(HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST, _loadContentByContentGroup);
}

function * _loadContentByContentGroup ({ payload }) {
  const { contentGroupId } = payload;
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isMobileApp = yield select(getIsMobileApp);
  const request = snakecaseKeys({ ...payload, domain, path: servicePath, isMobileApp });
  yield createRequest({
    api: {
      ...API_CONTENT.GET_CONTENT_BY_CONTENT_GROUP,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
      if (response.data.content && response.data.content.length === 0 && payload.page > 1) {
        yield put({
          type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_REQUEST,
          payload: {
            ...payload,
            page: payload.page - 1
          }
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.GET_CONTENT_BY_CONTENT_GROUP_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

function * loadContentsNextPrevPage () {
  yield takeEvery(HomeTypes.GET_PAGE_CONTENT_BY_CONTENT_GROUP_REQUEST, _loadContentsNextPrevPage);
}

function * _loadContentsNextPrevPage ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isAuthen = yield select(isAuthenticated);
  const isMobileApp = yield select(getIsMobileApp);
  const { page, size, contentGroupId, isNext, sort, sortField, searchRequests, searchKey, type, isIncremental, caseChangePage } = payload;
  let request = {};
  let data = [];
  let api = '';
  switch (type) {
    case TYPE_PRE_NEXT_PAGE.GET_CONTENT_BY_CONTENT_GROUP:
      request = snakecaseKeys({ page, size, sort, sortField, contentGroupId, domain, path: servicePath, isMobileApp });
      api = API_CONTENT.GET_CONTENT_BY_CONTENT_GROUP;
      break;
    case TYPE_PRE_NEXT_PAGE.SEARCH_CONTENT_BY_CONTENT_GROUP:
      request = snakecaseKeys({ page, size, sort, sortBy: sortField, contentGroupId, domain, path: servicePath, isMobileApp });
      data = snakecaseKeys([...searchRequests]);
      api = isAuthen ? API_SEARCH.GET_DATA : API_SEARCH.GET_PUBLIC_DATA;
      break;
    case TYPE_PRE_NEXT_PAGE.SEARCH_FULL_TEXT_BY_CONTENT_GROUP:
      request = snakecaseKeys({ domain: domain, path: servicePath, page, size, sort, sortField, contentGroupId, searchKey, isMobileApp });
      api = isAuthen ? API_SEARCH.SEARCH_FULL_TEXT_AUTHEN : API_SEARCH.SEARCH_FULL_TEXT;
      break;
    default:
      break;
  }
  delete request.is_next;
  yield createRequest({
    api: {
      ...api,
      data: data,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.GET_PAGE_CONTENT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          isNext,
          isIncremental,
          caseChangePage,
          type,
          contentGroupId
        })
      });
    }
  });
}

function * searchContentByContentGroup () {
  yield takeEvery(HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST, _searchContentByContentGroup);
}

function * _searchContentByContentGroup ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isMobileApp = yield select(getIsMobileApp);
  const { searchRequests, contentGroupId, pagination } = payload;
  const request = snakecaseKeys({ ...pagination, contentGroupId, sortField: pagination.sortField, domain, path: servicePath, isMobileApp });
  const data = snakecaseKeys([...searchRequests]);

  const isAuthen = yield select(isAuthenticated);
  const api = isAuthen ? API_SEARCH.GET_DATA : API_SEARCH.GET_PUBLIC_DATA;
  yield createRequest({
    api: {
      ...api,
      data: [...data],
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.SEARCH_CONTENT_BY_CONTENT_GROUP_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

function * searchContentFullText () {
  yield takeEvery(HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST, _searchContentFullText);
}

function * _searchContentFullText ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isAuthen = yield select(isAuthenticated);
  const isMobileApp = yield select(getIsMobileApp);
  const { contentGroupId, pagination, searchKey } = payload;
  const request = snakecaseKeys({ domain: domain, path: servicePath, ...pagination, searchKey, contentGroupId, isMobileApp });
  const api = isAuthen ? API_SEARCH.SEARCH_FULL_TEXT_AUTHEN : API_SEARCH.SEARCH_FULL_TEXT;
  yield createRequest({
    api: {
      ...api,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_SUCCESS,
        payload: Util.toCamelCaseKey({
          ...response.data,
          contentGroupId
        })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: HomeTypes.SEARCH_FULL_TEXT_BY_CONTENT_GROUP_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

export default function * contentGroupSaga () {
  yield all([
    loadPublicContentGroups(),
    loadContentByContentGroup(),
    loadContentsNextPrevPage(),
    searchContentByContentGroup(),
    searchContentFullText()
  ]);
}
