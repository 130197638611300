import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext, RouterContext } from './MainContainer';
import HomeHeader from 'components/home/HomeHeader';
import { Grid, makeStyles } from '@material-ui/core';
import HomeSidebar from 'components/home/HomeSidebar';
import {
  getLayoutViewPage,
  getIsLoadSetting,
  getLoginSetting,
  getAllowLoginByIpAddress,
  getLayoutSettingAll,
  getAccountType
} from 'state/search/searchSelector';
import {
  getAuthUser,
  isAuthenticated
} from 'state/session/sessionSelector';
import {
  getCompanyDomain, getPageMargin, getServicePath, getViewSettings
} from 'state/company/companySelector';
import {
  getCacheSearchRequest,
  getFulltextSearch,
  getPublicContentGroups,
  getSearchRequest,
  getShowFavorite,
  getShowHomeSearch,
  getSortOrder
} from 'state/home/homeSelector';
import { Redirect, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { AUTHEN_TYPES, HIDE_HOME_SIDEBAR, LAYOUT_SETTING, OPTION_LOGIN, PAGINATION, SETTING_LOGIN, SORT_FIELD_TYPE, STORAGE_KEYS, TAB_TYPE, TYPE_PRE_NEXT_PAGE } from 'configs/AppConfig';
import Util from 'utils/Util';
import { useMedia } from 'react-use';
import PublicFooter from 'components/core/PublicFooter';
import { getAllSort } from 'state/sort/sortSelector';
import StorageHelper from 'utils/StorageHelper';
import PublicAppBar from 'components/core/PublicAppBar';

const HomeLayout = props => {
  const {
    children,
    layoutViewPage,
    isLoadSetting,
    contentGroups,
    isAuthen,
    loginSetting,
    showFavorite,
    // showTrending,
    listSorts,
    showHomeSearch,
    searchRequest,
    fulltextSearch,
    cacheSearchRequest,
    sortOrder,
    accountType,
    homeActions: {
      loadContentByContentGroup: loadContents,
      loadContentsNextPrevPage,
      clearContents,
      searchContentByContentGroup: searchContents,
      searchFullText,
      setShowHomeSearch,
      setSearchRequest,
      setFulltextSearch,
      setSearchRequestFullTextSearch,
      setCacheSearchRequest,
      setSortOrder
    },
    sortActions: { loadAllSort },
    sessionActions: { login, getAuthInfo, setCallstartAppWithCustomAuth },
    paymentActions: { resetHtml }
  } = props;
  const style = useSelector((state) => state.search.bodySetting) || {};
  const viewSettings = useSelector(getViewSettings);
  const layoutSettingAll = useSelector(getLayoutSettingAll) || {};
  const pageMargin = useSelector(getPageMargin);
  const servicePath = useSelector(getServicePath);
  const isFulltextSearch = useSelector((state) => state.search.isFulltextSearch);
  const isCallStartAppWithCustomAuth = useSelector((state) => state.session.isCallStartAppWithCustomAuth);

  // const initSort = {
  //   sort: undefined,
  //   sortField: undefined
  // };
  const below768 = useMedia('(max-width: 768px)');
  const pathname = window.location.pathname;
  const search = window.location.search;

  const isHideSidebar = useMemo(() => {
    let res = false;
    for (const path of [...HIDE_HOME_SIDEBAR]) {
      if (pathname.includes(path)) {
        res = true;
      }
    }
    return res;
  }, [pathname]);
  const isRequireLogin = loginSetting === SETTING_LOGIN.LOGINREQUIRE.value;

  const useStyle = makeStyles(() => (
    {
      body: {
        backgroundColor: `${style.backgroundColor}!important`,
        display: layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id ? '' : pathname.includes('subscription') ? 'block' : 'flex',
        flexDirection: 'row',
        flex: '1 0 0px',
        fontSize: below768 ? '12px' : '16px',
        '& em': {
          // color: 'red'
        }
      },
      contain: {
        flex: '1 0 0px'
      },
      bodyLayout3: {
        marginLeft: below768 ? pathname.includes('profile') ? '10px' : '5px' : `${pageMargin.left}px`,
        marginRight: below768 ? pathname.includes('profile') ? '10px' : '5px' : `${pageMargin.right}px`
      }
    }
  ));

  const classes = useStyle();
  const history = useHistory();

  const [isOpenSidebar, setIsOpenSidebar] = useState(!below768);
  // const [searchRequest, setSearchRequest] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  // const [showTabSearch, setShowTabSearch] = useState(false);
  // const [fulltextSearch, setFulltextSearch] = useState();
  // const [isDisableSort, setIsDisableSort] = useState(false);
  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const [showContentDetail, setshowContentDetail] = useState(false);

  const showSidebarDetail = viewSettings.contentSearchResultSettingResponse.showSidebar && showContentDetail;

  const currentServicePath = StorageHelper.getSessionItem(STORAGE_KEYS.path);
  const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
  const userStore = !currentServicePath || currentServicePath === '' ? currentDomain : currentServicePath;
  const token = StorageHelper.getCookie(userStore);

  useEffect(() => {
    if (!isAuthen && token) {
      getAuthInfo();
    }
  }, []);

  useEffect(() => {
    const appMobileAuthToken = StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken);
    if (isCallStartAppWithCustomAuth && search.includes('?UserID=') && accountType === OPTION_LOGIN.GAKKEN_ID && appMobileAuthToken) {
      const request = {
        Token: appMobileAuthToken,
        CustomName: viewSettings.customName
      };
      window.BEObj.startAppWithCustomAuth(request);
      setCallstartAppWithCustomAuth(false);
    }
    // if (accountType === OPTION_LOGIN.GAKKEN_ID && isCallStartAppWithCustomAuth && appMobileAuthToken) {
    //   const request = {
    //     Token: appMobileAuthToken,
    //     CustomName: '689Cloud'
    //   };
    //   // setCallstartAppWithCustomAuth(false);
    //   window.BEObj.startAppWithCustomAuth(request);
    // }
  }, [isCallStartAppWithCustomAuth, search]);

  useEffect(() => {
    loadAllSort({ type: SORT_FIELD_TYPE.SORT_FIELD_HOME, path: servicePath });
  }, [servicePath]);

  useEffect(() => {
    if (loginSetting) {
      if (!isAuthen && isRequireLogin && !token) {
        history.push('/login');
      }
    }
  }, [isAuthen, loginSetting, token]);
  useEffect(() => {
    if (!pathname.includes('search') && layoutViewPage === LAYOUT_SETTING.SEARCH_LAYOUT.id && !pathname.includes('/profile')) {
      history.push('/search');
      return;
    }
    if (pathname.includes('home-search') && layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id) {
      history.push('/search');
    }
    if (pathname.includes('home-search') && layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT.id) {
      clearContents();
      setShowHomeSearch(true);
      // setIsDisableSort(true);
      below768 && setIsOpenSidebar(false);
    } else if (!pathname.includes('login')) {
      clearContents();
      setShowHomeSearch(false);
      below768 && setIsOpenSidebar(false);
    }
    if (!pathname.includes('search')) {
      if (cacheSearchRequest) {
        setCacheSearchRequest();
      }
    } else {
      setSortOrder({});
    }
    if (!pathname.includes('order-entry')) {
      resetHtml();
    }
  }, [pathname]);

  useEffect(() => {
    if (loginSetting || loginSetting === 0) {
      // if (!isRequireLogin) {
      doAutoIpLogin();
      // }
    }
  }, [loginSetting]);
  useEffect(() => {
    if (listSorts && listSorts.length > 0) {
      const defaultSort = listSorts.find(item => item.isSortFieldDefault);
      if (pathname.includes('search') && defaultSort && defaultSort.field === 12) {
        setSortOrder({});
      } else {
        defaultSort && setSortOrder({
          sort: defaultSort.isAcsDefault,
          sortField: defaultSort.field
        });
      }
    }
  }, [listSorts]);

  useEffect(() => {
    if (cacheSearchRequest) {
      setSearchRequest(cacheSearchRequest.searchRequest || []);
      // setCacheSearchRequest();
    } else {
      setSearchRequest([]);
      setFulltextSearch(null);
      setSearchRequestFullTextSearch([]);
    }
    clearContents();
  }, [showHomeSearch]);

  useEffect(() => {
    clearContents();
  }, [searchRequest, fulltextSearch]);

  const toggleSidebar = _updateValue => {
    // setIsOpenSidebar(prev => _updateValue === true || _updateValue === false ? _updateValue : prev === true || prev === false ? !prev : !isOpenSidebar);
    setIsOpenSidebar(prev => _updateValue === true || _updateValue === false ? _updateValue : !prev);
  };
  const changeSort = (sort) => {
    clearContents();
    setSortOrder(sort);
  };
  const onSelectContentGroup = (contentGroupId, isBackHome) => {
    const searchPath = '/home-search';
    if (isBackHome) {
      history.push(`${showHomeSearch ? searchPath : '/'}`);
    } else {
      history.push(`${showHomeSearch ? searchPath : ''}/${contentGroupId}`);
    }
    const cache = searchRequest;
    setCacheSearchRequest({ searchRequest: cache });
  };
  const doAutoIpLogin = async () => {
    const userTrace = await Util.getUserTrace();
    const ip = userTrace.ip;
    login({
      ip: ip,
      loginType: AUTHEN_TYPES.AUTO_IP
    });
  };

  const handleLoadContent = (contentGroup, page, size) => {
    const numItems = contentGroup.isWrapLine ? contentGroup.itemSize : contentGroup.numRow * contentGroup.itemSize;
    const params = {
      contentGroupId: contentGroup.id,
      page: page,
      path: servicePath,
      size: size || numItems,
      ...sortOrder
    };
    loadContents(params);
  };

  const handleLoadContentsNextPrevPage = ({ contentGroup, page, size, isNext, fulltextSearchValue, searchValues, isIncremental, caseChangePage }) => {
    const isHomeSearchPage = pathname.includes('home-search');
    const numItems = contentGroup.isWrapLine ? contentGroup.itemSize : contentGroup.numRow * contentGroup.itemSize;
    const params = {
      contentGroupId: contentGroup.id,
      page: page,
      path: servicePath,
      size: size || numItems,
      isNext,
      caseChangePage,
      isIncremental,
      ...sortOrder
    };
    if (isHomeSearchPage) {
      if (fulltextSearchValue) {
        params.type = TYPE_PRE_NEXT_PAGE.SEARCH_FULL_TEXT_BY_CONTENT_GROUP;
        params.searchKey = fulltextSearchValue;
      } else {
        params.type = TYPE_PRE_NEXT_PAGE.SEARCH_CONTENT_BY_CONTENT_GROUP;
        params.searchRequests = searchValues;
      }
    } else {
      params.type = TYPE_PRE_NEXT_PAGE.GET_CONTENT_BY_CONTENT_GROUP;
    }
    return loadContentsNextPrevPage(params);
  };
  const onSearch = (form, contentGroupId) => {
    if (form.tabType === TAB_TYPE.FULLTEXT_SEARCH.id) {
      setFulltextSearch(form.value);
    } else {
      // setFulltextSearch(null);
      let searchRequests = [];
      for (const item in form) {
        let value = form[item];

        if (Array.isArray(value) && value.length > 0) {
          let str = '';
          for (let item of value) {
            if (!item) {
              item = '';
            }
            if (item && item.value) {
              str += `${str === '' ? '' : ','}${item.value}`;
            } else {
              str += `${str === '' ? '' : ','}${item === '' ? '' : Util.formatDate(item, 'YYYY/MM/DD')}`;
            }
          }
          value = str;
        }

        if (value && value !== '' && value.length !== 0) {
          const param = {
            value: value.value || value.value === '' ? value.value : value,
            searchBoxId: parseInt(item.replace('search', ''), 10)
          };
          if (form[item] && form[item].contentGroupIdList) {
            param.contentGroupIdList = form[item].contentGroupIdList;
          }
          searchRequests = [...searchRequests, param];
        }
      }
      setSearchRequest(searchRequests);
    }
  };
  const handleChangeSearchRequest = (form) => {
    onSearch(form);
  };

  const handleSearchContent = (contentGroup, page, size, fulltextSearchValue, searchValues, isSearchFullText) => {
    const numItems = contentGroup ? (contentGroup.isWrapLine ? contentGroup.itemSize : contentGroup.numRow * contentGroup.itemSize) : PAGINATION.size;
    if (fulltextSearchValue && isSearchFullText) {
      if (sortOrder && sortOrder.sortField !== 12) {
        searchFullText({
          searchKey: fulltextSearchValue,
          contentGroupId: contentGroup ? contentGroup.id : null,
          pagination: {
            page: page,
            size: size || numItems,
            ...sortOrder
          }
        });
      }
    } else {
      if (isFulltextSearch) {
        if (sortOrder && sortOrder.sortField !== 12) {
          searchFullText({
            searchKey: fulltextSearchValue,
            contentGroupId: contentGroup ? contentGroup.id : null,
            pagination: {
              page: page,
              size: size || numItems,
              ...sortOrder
            }
          });
        }
      } else {
        const params = {
          searchRequests: searchValues,
          contentGroupId: contentGroup ? contentGroup.id : null,
          pagination: {
            page: page,
            size: size || numItems,
            ...sortOrder
          }
        };
        if (sortOrder && sortOrder.sortField === 12) {
          delete params.sort;
          delete params.sortField;
        }
        searchContents(params);
      }
    }
  };

  useEffect(() => {
    if (cacheSearchRequest) {
      setSearchRequest(cacheSearchRequest.searchRequest);
    }
  }, [cacheSearchRequest]);

  if (isLoadSetting) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} /> <TranslateMessage id="progress.loading" />
      </Grid>);
  } else {
    const cacheLoginMobileApp = StorageHelper.getCookie(STORAGE_KEYS.cacheLoginMobileApp);
    if ((isRequireLogin && !isAuthen && !token) || pathname.includes('login')) {
      return <Redirect to={`/login${cacheLoginMobileApp ? '?is_login_mobile_app=true' : ''}`} push/>;
    } else {
      if (layoutViewPage === LAYOUT_SETTING.SEARCH_LAYOUT.id && !pathname.includes('/profile')) {
        return <Redirect to={'/search'} push />;
      } else {
        return (
          <div className='wrapper'>
            {
              layoutViewPage === LAYOUT_SETTING.SEARCH_LAYOUT.id && pathname.includes('/profile') ? (<PublicAppBar location={window.location} />)
                : <HomeHeader
                  toggleSidebar={toggleSidebar}
                  showLoginFormModal={showLoginFormModal}
                  layoutViewPage={layoutViewPage}
                  setShowLoginFormModal={setShowLoginFormModal}
                  isOpenSidebar={isOpenSidebar}
                  showSidebar={layoutSettingAll.showSidebar || showSidebarDetail}
                  setShowTabSearch={setShowHomeSearch}
                  isContentDetail={showContentDetail}
                  setCacheSearchRequest={setCacheSearchRequest}
                  setSearchRequest={setSearchRequest}
                  setFulltextSearch={setFulltextSearch}
                  setSearchRequestFullTextSearch={setSearchRequestFullTextSearch}
                  clearContents={clearContents}
                />
            }

            {layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id && classes.bodyLayout3
              ? <div role="main" className={`${classes.body} ${(!isOpenSidebar || below768 || isHideSidebar) && ' body-full '}`}>
                <div className={`${layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id && !pathname.includes('/profile') && !pathname.includes('/subscription') ? classes.bodyLayout3 : classes.body}`}>
                  <React.Fragment>
                    {
                      (!viewSettings.contentSearchResultSettingResponse.showSidebar && showContentDetail) || isHideSidebar || layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id
                        ? null
                        : (layoutSettingAll.showSidebar || showSidebarDetail) && <HomeSidebar
                          isOpenSidebar={isOpenSidebar}
                          setIsOpenSidebar={setIsOpenSidebar}
                          contentGroups={contentGroups}
                          onSelectContentGroup={onSelectContentGroup}
                          sort={sortOrder}
                          pageSize={pageSize}
                          changeSort={changeSort}
                          // isDisableSort={isDisableSort}
                          listSorts={listSorts}
                          setShowLoginFormModal={setShowLoginFormModal}
                          handleLoadContent={handleLoadContent}
                          showSidebar={layoutSettingAll.showSidebar || showSidebarDetail}
                        />
                    }
                    {cloneElement(children, {
                      sortOrder: sortOrder,
                      contentGroups: contentGroups,
                      handleLoadContent: handleLoadContent,
                      handleLoadContentsNextPrevPage: handleLoadContentsNextPrevPage,
                      handleSearchContent: handleSearchContent,
                      onSelectContentGroup: onSelectContentGroup,
                      setPageSize: setPageSize,
                      pageSize: pageSize,
                      showTabSearch: showHomeSearch,
                      hideSearchResult: showHomeSearch ? ((!searchRequest || searchRequest.length <= 0) && !fulltextSearch) : false,
                      handleSearch: handleChangeSearchRequest,
                      searchRequest: searchRequest,
                      fulltextSearch: fulltextSearch,
                      // setIsDisableSort: setIsDisableSort,
                      showFavorite: showFavorite,
                      listSorts: listSorts,
                      // showTrending: showTrending,
                      setSearchRequest: setSearchRequest,
                      toggleSidebar: toggleSidebar,
                      showContentDetail: showContentDetail,
                      setshowContentDetail: setshowContentDetail,
                      changeSort: changeSort,
                      cacheSearchRequest: cacheSearchRequest
                    })
                    }
                  </React.Fragment>
                </div>
              </div> : <div role="main" className={`${classes.body} ${(!isOpenSidebar || below768 || isHideSidebar) && ' body-full '}`}>
                <React.Fragment>
                  {
                    (!viewSettings.contentSearchResultSettingResponse.showSidebar && showContentDetail) || isHideSidebar || layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id
                      ? null
                      : (layoutSettingAll.showSidebar || showSidebarDetail) && <HomeSidebar
                        isOpenSidebar={isOpenSidebar}
                        setIsOpenSidebar={setIsOpenSidebar}
                        contentGroups={contentGroups}
                        onSelectContentGroup={onSelectContentGroup}
                        sort={sortOrder}
                        pageSize={pageSize}
                        changeSort={changeSort}
                        // isDisableSort={isDisableSort}
                        listSorts={listSorts}
                        setShowLoginFormModal={setShowLoginFormModal}
                        handleLoadContent={handleLoadContent}
                        showSidebar={layoutSettingAll.showSidebar || showSidebarDetail}
                      />
                  }
                  {cloneElement(children, {
                    sortOrder: sortOrder,
                    contentGroups: contentGroups,
                    handleLoadContent: handleLoadContent,
                    handleLoadContentsNextPrevPage: handleLoadContentsNextPrevPage,
                    handleSearchContent: handleSearchContent,
                    onSelectContentGroup: onSelectContentGroup,
                    setPageSize: setPageSize,
                    pageSize: pageSize,
                    showTabSearch: showHomeSearch,
                    hideSearchResult: showHomeSearch ? ((!searchRequest || searchRequest.length <= 0) && !fulltextSearch) : false,
                    handleSearch: handleChangeSearchRequest,
                    searchRequest: searchRequest,
                    fulltextSearch: fulltextSearch,
                    // setIsDisableSort: setIsDisableSort,
                    showFavorite: showFavorite,
                    listSorts: listSorts,
                    // showTrending: showTrending,
                    setSearchRequest: setSearchRequest,
                    setFulltextSearch: setFulltextSearch,
                    toggleSidebar: toggleSidebar,
                    showContentDetail: showContentDetail,
                    setshowContentDetail: setshowContentDetail,
                    changeSort: changeSort,
                    cacheSearchRequest: cacheSearchRequest
                  })
                  }
                </React.Fragment>
              </div>}

            <PublicFooter />
          </div>
        );
      }
    }
  }
};

HomeLayout.propTypes = {
  router: PropTypes.any,
  layoutViewPage: PropTypes.any,
  isLoadSetting: PropTypes.bool,
  routeActions: PropTypes.any,
  contentGroupActions: PropTypes.any,
  homeActions: PropTypes.any,
  sortActions: PropTypes.any,
  sessionActions: PropTypes.any,
  scrollbarActions: PropTypes.any,
  paymentActions: PropTypes.any,
  contentGroups: PropTypes.any,
  domain: PropTypes.any,
  loginSetting: PropTypes.any,
  getPublicContentGroups: PropTypes.array,
  notificationActions: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  isAuthen: PropTypes.bool,
  listSorts: PropTypes.any,
  children: PropTypes.any,
  showFavorite: PropTypes.bool,
  // showTrending: PropTypes.bool,
  allowLoginByIpAddress: PropTypes.bool,
  searchRequest: PropTypes.array,
  fulltextSearch: PropTypes.any,
  showHomeSearch: PropTypes.bool,
  cacheSearchRequest: PropTypes.object,
  sortOrder: PropTypes.object,
  accountType: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    layoutViewPage: getLayoutViewPage(state),
    isLoadSetting: getIsLoadSetting(state),
    contentGroups: getPublicContentGroups(state),
    isAuthen: isAuthenticated(state),
    authUser: getAuthUser(state),
    domain: getCompanyDomain(state),
    loginSetting: getLoginSetting(state),
    showFavorite: getShowFavorite(state),
    // showTrending: getShowTrending(state),
    listSorts: getAllSort(state),
    allowLoginByIpAddress: getAllowLoginByIpAddress(state),
    cacheSearchRequest: getCacheSearchRequest(state),
    showHomeSearch: getShowHomeSearch(state),
    searchRequest: getSearchRequest(state),
    fulltextSearch: getFulltextSearch(state),
    accountType: getAccountType(state),
    sortOrder: getSortOrder(state)
  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {({
          contentGroupActions,
          homeActions,
          sessionActions,
          routeActions,
          scrollbarActions,
          sortActions,
          notificationActions,
          paymentActions
        }) => (
          <HomeLayout
            {...props}
            router={router}
            contentGroupActions={contentGroupActions}
            notificationActions={notificationActions}
            homeActions={homeActions}
            sessionActions={sessionActions}
            routeActions={routeActions}
            scrollbarActions={scrollbarActions}
            sortActions={sortActions}
            paymentActions={paymentActions}
          />
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
